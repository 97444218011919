import { Row, Col, Container, size, Button } from '@everlywell/leaves';
import SectionWrapper from 'components/SectionWrapper';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import React, { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring, config, animated as A } from 'react-spring';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { generateKey } from 'utils/helpers';
import { CTA, IGatsbyImage } from 'utils/types';

import * as S from './styles';

export enum LayoutVariation {
  Default = 'Default',
  Reversed = 'Reversed',
}

export interface TwoColumnFullWidthImageProps {
  uid?: string;
  title: string;
  headline: string;
  subHeadline: string;
  description?: string;
  mainCta: CTA;
  desktopImage?: IGatsbyImage;
  mobileImage?: IGatsbyImage;
  layoutVariation: LayoutVariation;
  backgroundColor: 'Primary' | 'Secondary';
  showDescriptionOnMobile?: boolean;
  deprecatedCtaStyle?: boolean;
  style?: any;
}

export const TwoColumnFullWidthImage: React.FC<
  TwoColumnFullWidthImageProps
> = ({
  uid = `two-col-full-width-${generateKey()}`,
  title,
  headline,
  subHeadline,
  description,
  mainCta,
  desktopImage,
  mobileImage,
  layoutVariation = LayoutVariation.Default,
  backgroundColor,
  showDescriptionOnMobile,
  deprecatedCtaStyle = false,
  style,
}) => {
  // state for triggering animation springs
  const [desktopImageOffset, setDesktopImageOffset] = useState<number>(0);

  const desktopImageRef = useRef<HTMLDivElement>(null);
  // get the desktop Image's height and set for use in desktopImageTransition spring
  useEffect(() => {
    if (desktopImageRef && desktopImageRef.current) {
      setDesktopImageOffset(desktopImageRef.current.clientHeight);
    }
  }, [desktopImageRef]);

  const [sectionRef, sectionInView] = useInView({ triggerOnce: true });

  // triggered from scrolling title into view
  const titleTransition = useSpring({
    opacity: sectionInView ? 1 : 0,
    transform: sectionInView ? 'translateY(0)' : `translateY(${size.xl1}px)`,
    config: { ...config.slow, friction: 75 },
  });

  // triggered from scrolling headline into view
  const headlineTransition = useSpring({
    opacity: sectionInView ? 1 : 0,
    transform: sectionInView ? 'translateY(0)' : `translateY(${size.xl2}px)`,
    config: { ...config.slow, friction: 75 },
  });

  // triggered from scrolling headline into view
  const buttonTransition = useSpring({
    opacity: sectionInView ? 1 : 0,
    transform: sectionInView ? 'translateY(0)' : `translateY(${size.xl2}px)`,
    config: { ...config.slow, friction: 75 },
  });

  // triggered from scrolling headline into view
  const desktopImageTransition = useSpring({
    transform: sectionInView
      ? 'translateY(0)'
      : `translateY(${desktopImageOffset * 1.75}px)`,
    config: { ...config.slow, friction: 75 },
  });

  // triggered from scrolling headline into view
  const imageZoomTransition = useSpring({
    transform: sectionInView ? 'scale(1)' : 'scale(1.75)',
    config: { ...config.slow, friction: 75 },
  });

  const mobileImageZoomTransition = useSpring({
    transform: sectionInView ? 'scale(1)' : 'scale(1.3)',
  });

  const handleClick = () => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: `${uid}-cta`,
      },
    });
  };

  const MobileImage = () =>
    mobileImage ? (
      <S.MobileImageWrapper>
        <A.div style={mobileImageZoomTransition}>
          <Img
            image={mobileImage.gatsbyImageData}
            alt={mobileImage.description || ''}
          />
        </A.div>
      </S.MobileImageWrapper>
    ) : null;

  const DesktopImage = () =>
    desktopImage ? (
      <S.DesktopImageWrapper ref={desktopImageRef}>
        <S.AnimatedImageWrapper style={desktopImageTransition}>
          <A.div style={imageZoomTransition}>
            <Img
              image={desktopImage.gatsbyImageData}
              alt={desktopImage.description || ''}
            />
          </A.div>
        </S.AnimatedImageWrapper>
      </S.DesktopImageWrapper>
    ) : null;

  const SectionContent = () => (
    <S.ContentWrapper>
      <A.div style={titleTransition}>
        {title ? <S.Title text={title} /> : null}
      </A.div>
      <A.div style={headlineTransition}>
        {headline ? <S.Headline>{headline}</S.Headline> : null}
      </A.div>
      <A.div style={headlineTransition}>
        {subHeadline ? <S.SubHeadline>{subHeadline}</S.SubHeadline> : null}
      </A.div>
      <A.div>
        {description ? (
          <S.Description
            body={description}
            showDescriptionOnMobile={showDescriptionOnMobile}
          />
        ) : null}
      </A.div>
      <A.div style={buttonTransition}>
        {mainCta ? (
          !deprecatedCtaStyle ? (
            <Button
              appearance={mainCta.appearance ? mainCta.appearance : 'primary'}
              href={mainCta.url}
              data-testid={`${uid}-cta`}
              onClick={handleClick}
            >
              {mainCta.text}
            </Button>
          ) : (
            <S.ButtonWrapper>
              <S.DeprecatedButton
                href={mainCta.url}
                onClick={handleClick}
                data-testid={`${uid}-cta`}
              >
                {mainCta.text}
              </S.DeprecatedButton>
            </S.ButtonWrapper>
          )
        ) : null}
      </A.div>
    </S.ContentWrapper>
  );

  return (
    <SectionWrapper
      ref={sectionRef}
      data-testid={`${uid}-section`}
      backgroundColor={backgroundColor}
      style={{ ...style }}
    >
      {/* Layout variation to allow for minor polymorphism.
          The Row component has a `reversed` prop, but it does not acount for
          reversing the width of the columns, in only reorders the Columns.
          This is a good candidate for updateing to CSS grid.
      */}
      {layoutVariation === LayoutVariation.Default ? (
        <>
          <MobileImage />
          <Container>
            <Row middle="xs">
              <Col md={5}>
                <SectionContent />
              </Col>
              <Col md={6} mdOffset={1}>
                <DesktopImage />
              </Col>
            </Row>
          </Container>
        </>
      ) : null}
      {layoutVariation === LayoutVariation.Reversed ? (
        <>
          <MobileImage />
          <Container>
            <Row middle="xs">
              <Col md={5}>
                <DesktopImage />
              </Col>
              <Col md={6} mdOffset={1}>
                <SectionContent />
              </Col>
            </Row>
          </Container>
        </>
      ) : null}
    </SectionWrapper>
  );
};

export default TwoColumnFullWidthImage;
