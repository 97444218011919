import { mediaQueries, size, H2, typography } from '@everlywell/leaves';
import { DeprecatedButtonStyles } from 'components/ProductCard/styles';
import { animated } from 'react-spring';
import styled from 'styled-components';

import { MarkdownContent } from '../../Contentful/MarkdownContent';
import SectionTitle from '../../Enterprise/SectionTitle';

export const MobileImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;
export const DesktopImageWrapper = styled.div`
  width: 100%;
  display: none;
  ${mediaQueries.forTabletHorizontalUp} {
    display: block;
    overflow: hidden;
  }
`;

export const AnimatedImageWrapper = styled(animated.div)``;

export const ContentWrapper = styled.div`
  padding-top: ${size.xl1}px;
  ${mediaQueries.forTabletHorizontalUp} {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const Title = styled(SectionTitle)`
  margin-bottom: 14px;
  ${mediaQueries.forTabletHorizontalUp} {
    text-align: left;
  }
`;

export const Headline = styled(H2)`
  margin-bottom: ${size.lg}px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xs1}px;
  }
`;

export const SubHeadline = styled.p`
  display: none;
  ${mediaQueries.forTabletHorizontalUp} {
    display: block;
    ${typography.bodyText};
    margin-bottom: ${size.xl1}px;
  }
`;

interface DescriptionProps {
  showDescriptionOnMobile?: boolean;
}
export const Description = styled(MarkdownContent)<DescriptionProps>`
  display: ${(props) => (props.showDescriptionOnMobile ? 'block' : 'none')};
  ${typography.bodyText};
  ${mediaQueries.forTabletHorizontalUp} {
    display: block;
    margin-bottom: ${size.xl1}px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: ${size.xl1}px;
`;

export const DeprecatedButton = styled.a`
  ${DeprecatedButtonStyles}
  text-decoration: none;
  width: 100%;
  max-width: 230px;
  text-align: center;
  text-transform: none;
  font-size: 18.7px;
  padding: 10px 21px;
`;
