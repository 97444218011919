import TwoColumnFullWidthImage, {
  LayoutVariation,
} from 'components/PageSections/TwoColumnFullWidthImage';
import React from 'react';

import { CTA, IGatsbyImage } from 'utils/types';

type Markdown = {
  childMarkdownRemark: {
    html: string;
  };
};

export interface TwoColumnFullWidthImageProps {
  content: {
    slug: string;
    title: string;
    headline: string;
    subHeadline: string;
    description: Markdown;
    mainCta: CTA;
    desktopImages: IGatsbyImage[];
    mobileImages: IGatsbyImage[];
    componentVariation?: string;
    backgroundColor?: 'Primary' | 'Secondary';
  };
}

export const TwoColumnFullWidthImageContainer: React.FC<TwoColumnFullWidthImageProps> =
  ({ content }) => {
    if (!content) {
      return null;
    }

    const {
      slug,
      title,
      headline,
      subHeadline,
      description,
      mainCta,
      desktopImages: rawDesktopImages,
      mobileImages: rawMobileImages,
      componentVariation = 'Default',
      backgroundColor = 'Primary',
    } = content;

    const desktopImage = rawDesktopImages ? rawDesktopImages[0] : undefined;
    const mobileImage = rawMobileImages ? rawMobileImages[0] : undefined;

    // Eww. We need to cast the string to an Enum, though.
    const layoutVariation =
      LayoutVariation[componentVariation as keyof typeof LayoutVariation];

    return (
      <TwoColumnFullWidthImage
        uid={slug}
        title={title}
        headline={headline}
        subHeadline={subHeadline}
        description={description?.childMarkdownRemark.html}
        mainCta={mainCta}
        desktopImage={desktopImage}
        mobileImage={mobileImage}
        layoutVariation={layoutVariation}
        backgroundColor={backgroundColor}
        showDescriptionOnMobile
      />
    );
  };

export default TwoColumnFullWidthImageContainer;
